import { Cart } from 'service/types/generated';
import { initializeCartMutation } from '../../mutations';
import { apiMutate } from '../../utils/mutation';
import ServiceConfigs from '../../config';
import { CreateCartVarsType } from './types';

type UseInitializeCartType = () => (variables: CreateCartVarsType) => Promise<Cart['uuid']>;

const useInitializeCart: UseInitializeCartType = () => variables =>
  apiMutate({
    mutation: initializeCartMutation,
    variables: {
      ...variables,
      origin: 'storefront',
      subdomain: ServiceConfigs.getSubDomain(),
      isFromOrderfast: !!ServiceConfigs.getOrderFastStore(),
    },
    requestHeaders: { ...ServiceConfigs.getAuthorizationHeader() },
    handler: (res: any) => res.createCart.uuid,
  }).then(cartId => cartId);

export default useInitializeCart;
