import ServiceConfigs from 'service/config';
import { apiMutate } from '../../utils/mutation';
import { verifyVoucherMutation } from '../../mutations';
import { Mutation } from '../../types';

type VerifyVoucherType = () => (code: string, isScheduled: boolean, cartId: string) => Promise<Mutation['verifyVoucher']>;

const useVerifyVoucher: VerifyVoucherType = () => (code, isScheduled, cartId) =>
  apiMutate({
    mutation: verifyVoucherMutation,
    variables: { subdomain: ServiceConfigs.getSubDomain(), code, isScheduled, cartId },
    requestHeaders: { ...ServiceConfigs.getAuthorizationHeader() },
    handler: (res: { verifyVoucher: Mutation['verifyVoucher'] }) => res?.verifyVoucher,
  });

export default useVerifyVoucher;
